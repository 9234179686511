import React, { useState, useEffect } from "react";
import { Carousel } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import polo from '../assets/TECHNOPOLO.jpg';
import blipack from '../assets/BLIPACK-300x60.png'
import aerosoles from '../assets/logo-AEROSOLES.png'
import consorcio1 from '../assets/admin consrocio 1.jpeg'
import consorcio2 from '../assets/admin consorcio 2.jpeg'

const ImageCarousel = () => {
  const images = [
    { id: 4, src: {polo}, alt: "Imagen 4" },
    { id: 2, src: {blipack}, alt: "Imagen 2" },
    { id: 1, src: {aerosoles}, alt: "Imagen 1" },
    { id: 5, src: {consorcio1}, alt:"Imagen 5" },
    { id: 3, src: {consorcio2}, alt:"Imagen 3" },
  ];

  
  return (
    
      <div className="slider-c">
        <div className="slide-track">
            <div className="slide-c">
                <img className="img-c" src={polo}/>
            </div>
            <div className="slide-c">
                <img className="img-c" src={blipack}/>
            </div>
            <div className="slide-c">
                <img className="img-c" src={aerosoles}/>
            </div>
            <div className="slide-c">
                <img className="img-c" src={consorcio1}/>
            </div>
            <div className="slide-c">
                <img className="img-c" src={consorcio2}/>
            </div>



            <div className="slide-c">
                <img className="img-c" src={polo}/>
            </div>
            <div className="slide-c">
                <img className="img-c" src={blipack}/>
            </div>
            <div className="slide-c">
                <img className="img-c" src={aerosoles}/>
            </div>
            <div className="slide-c">
                <img className="img-c" src={consorcio1}/>
            </div>
            <div className="slide-c">
                <img className="img-c" src={consorcio2}/>
            </div>

        </div>
      </div>
      

  );
}

export default ImageCarousel;