import {React, useEffect} from 'react';
import { useLocation } from "react-router-dom";
import ServiciosCarousel from './ServiciosCarousel';
import ModalRequest from './ModalRequest'

import '../App.css'; 
import fabrica from '../assets/FABRICA-2.jpg'
import mochila from '../assets/mochila.jpg'
import corp3 from "../assets/pagina web 2/Seguridad corpo 3.jpg"
import controlacc from '../assets/pagina web 2/molinete electron.jpg'
import seguridad from '../assets/pagina web 2/camaras.jpeg'
import biometria from '../assets/biometrico.jpg'
import auto from '../assets/handyauto.jpg'
import barrio from '../assets/residencial2.jpg'
import barrio2 from '../assets/pagina web 2/residencial 3.jpg'
import comercio from '../assets/comercio 1.jpeg'
import comercio3 from '../assets/comercio 3.jpeg'
import comercio4 from '../assets/comercio 4.jpeg'
import comercio5 from '../assets/comercio 5.jpeg'
import comercio2 from '../assets/comercio 2.jpeg'
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import telefono from '../assets/pagina web 2/lucas telefono oficina.jpg'
import trato from '../assets/MANOS 1.jpg'
import monitoreo from '../assets/pagina web 2/MONITOREO LUCAS HANDY.jpg'

const Servicios = () => {
  const Escudo = () => (
    <svg xmlns="https://www.w3.org/2000/svg" viewBox="0 0 512 512" className='escudo'>
      <path d="M256-.0078C260.7-.0081 265.2 1.008 269.4 2.913L457.7 82.79C479.7 92.12 496.2 113.8 496 139.1C495.5 239.2 454.7 420.7 282.4 503.2C265.7 511.1 246.3 511.1 229.6 503.2C57.25 420.7 16.49 239.2 15.1 139.1C15.87 113.8 32.32 92.12 54.3 82.79L242.7 2.913C246.8 1.008 251.4-.0081 256-.0078V-.0078zM256 444.8C393.1 378 431.1 230.1 432 141.4L256 66.77L256 444.8z"></path></svg>
  );
  //////////////////////////////////////

  const corporativa = [
    { id: 1, src:fabrica, alt: "Imagen 1" },
    { id: 2, src:mochila, alt: "Imagen 2" },
    { id: 3, src:corp3, alt: "Imagen 3" },
  ];

  ////////////////
  const residencial = [
    { id: 1, src:auto, alt: "Imagen 1" },
    { id: 2, src:barrio, alt: "Imagen 2" },
    { id: 3, src:barrio2, alt: "Imagen 3" },
  ];

  ///////////////////////////
  const scomercio = [
    { id: 1, src:comercio, alt: "Imagen 1" },
    { id: 2, src:comercio3, alt: "Imagen 2" },
    { id: 3, src:comercio4, alt: "Imagen 3" },
    { id: 4, src:comercio5, alt: "Imagen 4" }
  ];

  ///////////////////////////
  const dispos = [
    { id: 1, src:controlacc, alt: "Imagen 1"},
    { id: 2, src:seguridad, alt: "Imagen 2"},
    { id: 3, src:biometria, alt: "Imagen 3"},
  ];

///////////////////////


  const IconoFlecha = () => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 448 512"
      className="icono-flecha"
    >
      <path d="M246.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L178.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C47.63 444.9 55.81 448 64 448s16.38-3.125 22.62-9.375l160-160C259.1 266.1 259.1 245.9 246.6 233.4zM438.6 233.4l-160-160c-12.5-12.5-32.75-12.5-45.25 0s-12.5 32.75 0 45.25L370.8 256l-137.4 137.4c-12.5 12.5-12.5 32.75 0 45.25C239.6 444.9 247.8 448 256 448s16.38-3.125 22.62-9.375l160-160C451.1 266.1 451.1 245.9 438.6 233.4z"></path>
    </svg>
  );

///////////////////////////////////


  return (
    <div  className="company-info-section">
      <div className="header-section-servicios">
        <h1 className="header-title"></h1>
      </div>
      <Container  className="my-5 company-info-section container-ser">
        <h2 className="text-center mb-5 ">Nuestro trabajo</h2>
        <p  className="text-center description">
          Nos especializamos en soluciones globales de seguridad privada, priorizando siempre a nuestros clientes.
          Buscamos superar sus expectativas con servicios excepcionales y relaciones comerciales sólidas y duraderas, 
          aspirando a la excelencia en nuestro trabajo.
        </p>
        <div id="industrias" ></div>
        <div >
          <hr className="divider2 my-5" />
        </div>
        <Row className="align-items-center">
          <Col md={4} className="">
            <ServiciosCarousel images={corporativa} interval={2000} />
          </Col>
          <Col className="my-3" md={8}>
            <h1>Seguridad Física Corporativa</h1>
            <p className="description"> 
            Ofrecemos servicio a corporaciones como fábricas, laboratorios y todo tipo de empresas con el objetivo de desarrollar un plan de seguridad con un equipo responsable. 
            </p>
            <ul>
              <li className="description"><IconoFlecha /> Controles de acceso de personal, visitas y proveedores.</li>
              <li className="description"><IconoFlecha /> Control de vehículos e instalaciones.</li>
              <li className="description"><IconoFlecha /> Monitoreo del perímetro 24hs.</li>
            </ul>
          </Col>
        </Row>
        <div id='barrio-cerrado' className='my-5'><p className='p-id'> g</p></div>
        <div>
          <hr className="divider2 my-5" />
        </div>
        <Row className="align-items-center sfr" >
          <Col md={7} className="px-5">
              <h1>Seguridad Física Residencial</h1>
              <p className="description">
              Soluciones de seguridad a la medida de cada barrio cerrado, condominio o edificio. Somos especialistas en vigilancia y protección de bienes.
              </p>
              <ul>
                <li className="description"><IconoFlecha />  Personal capacitado y equipado con elementos necesarios para cada objetivo.</li>
                <li className="description"><IconoFlecha />  Móviles de recorrido.</li>
                <li className="description"><IconoFlecha />  Monitoreo del perímetro.</li>
                <li className="description"><IconoFlecha />  Control de accesos.</li>
              </ul>
          </Col>
          <Col md={4} className="">  
            <ServiciosCarousel images={residencial} interval={2000} />
          </Col>  
        </Row>
        <div  id="comercios"></div>
        <div>
          <hr className="divider2 my-5" />
        </div>
        <Row className="align-items-center">
          <Col md={4} className="image-col">
            <ServiciosCarousel images={scomercio} interval={2000} />
          </Col>
          <Col className="px-5 " md={8}>

            <h1 className="mt-3"> Seguridad Física en Comercios</h1>
            <p className="description">
              Centros comerciales, supermercados, y otros comercios.
            </p>
            <ul>
              <li className="description"><IconoFlecha />Control de las instalaciones.</li>
              <li className="description"><IconoFlecha />Monitoreo CCTV.</li>
              <li className="description"><IconoFlecha />Protocolos de seguridad.</li>
            </ul>
          </Col>
        </Row>
        <hr className="divider2 my-5"/>
        <Row className="align-items-center">
          <Col md={7} className="px-5">
            <h1>Dispositivos de seguridad electrónica</h1>
            <p className="description">
              Contamos con servicios complementarios que potencian la seguridad.
            </p>
            <ul>
              <li className="description"><IconoFlecha />  Sistemas de CCTV</li>
              <li className="description"><IconoFlecha />  Sistemas de acceso eléctrico</li>
              <li className="description"><IconoFlecha />  Sistemas de control de acceso biométrico.</li>
            </ul>
          </Col>
          <Col md={4} className="">
            <ServiciosCarousel images={dispos} interval={2000} className='' />
          </Col>
        </Row>
     </Container>
     <Container fluid style={{ backgroundColor: 'white' }}>
        <div className="company-info-section" style={{ backgroundColor: 'white' }}> 
          <Row className="align-items-center justify-content-center p-5 company-info-section" style={{ backgroundColor: 'white' }}>
            <Col lg={4} md={12} xs={12} sm={12} className="text-black mb-4 mb-md-0">
              <h2>POR QUÉ ELEGIRNOS</h2>
              <p>
                Nos especializamos en soluciones globales de seguridad privada, priorizando siempre a nuestros clientes. 
                Buscamos superar sus expectativas con servicios excepcionales y relaciones comerciales sólidas y duraderas.
              </p>
            </Col>
            <Col lg={8} md={12} xs={12} sm={12} className="d-flex flex-wrap justify-content-center">
              <Card className="text-white card-custom m-1" >
                <div className='card-img2'>
                  <Card.Img src={telefono} alt="Soluciones" className="card-img2" />
                </div>
                <Card.ImgOverlay className="d-flex flex-column justify-content-between text-center">
                  <div className="card-content">
                    <Escudo/>
                    <Card.Title>RESPUESTA INMEDIATA Y EFECTIVA</Card.Title>
                    <Card.Text className='card-text text-white'>
                      Nuestro equipo especializado brinda una respuesta rápida y confiable en cualquier situación que el cliente lo requiera.
                    </Card.Text>
                  </div>
                  <ModalRequest />
                </Card.ImgOverlay>
              </Card>
              <Card className="text-white card-custom m-1" >
                <div className='card-img2'>
                  <Card.Img src={trato} alt="Soluciones" className="card-img2" />
                </div>
                <Card.ImgOverlay className="d-flex flex-column justify-content-between text-center">
                  <div className="card-content">
                    <Escudo/>
                    <Card.Title>SOLUCIONES ACORDE A LAS NECESIDADES</Card.Title>
                    <Card.Text className='card-text text-white'>
                      Proponemos soluciones a la medida y brindamos acompañamiento técnico para la toma de decisiones.
                    </Card.Text>
                  </div>
                  <ModalRequest />
                </Card.ImgOverlay>
              </Card>
              <Card className="text-white card-custom m-1">
                <div className='card-img2'>
                  <Card.Img src={monitoreo} alt="Supervisión" className="card-img2" />
                </div>
                <Card.ImgOverlay className="d-flex flex-column justify-content-between text-center">
                  <div className="card-content">
                    <Escudo/>
                    <Card.Title>SUPERVISIÓN DE CADA OBJETIVO</Card.Title>
                    <Card.Text className='card-text text-white'>
                      Nuestro servicio de seguridad integral permite que el cliente esté informado en tiempo real de la operación en ejecución.
                    </Card.Text>
                  </div>
                  <ModalRequest />
                </Card.ImgOverlay>
              </Card>
            </Col>
          </Row>
        </div>
      </Container>
    </div>   
  );
};

export default Servicios;
