import React from "react";
import { Container } from 'react-bootstrap';

const VideoQuienes = () => {
    return (
        <Container className="my-4">
          <div className="ratio ratio-16x9">
            <iframe
              src="https://www.youtube.com/embed/qPe7Twew5G4"
              title="YouTube video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </Container>
      );
    };

export default VideoQuienes;

