import {React} from 'react';
import '../App.css'; 
import { Container, Row, Col} from 'react-bootstrap';
import VideoQuienes from './VideoQuienes';

const Quienes = () => {

  return (
    <>
    <div className="header-section-quienes">
      <h1 className="header-title"></h1>
    </div>
    <Container fluid className="company-info-section py-5">
      <Row className='px-5'>
        <Col md={12}>
          <h2 >¿QUIENES SOMOS?</h2>
          <p className="description">
            Somos una empresa con amplia experiencia que brinda soluciones en materia de seguridad a la medida de cada cliente. Esta tarea incluye propuestas acorde a sus necesidades y el acompañamiento en la toma de decisiones.
          </p>
        </Col>
      </Row>
      <Row className='mt-5 px-5'>
        <Col md={12}>
          <h2>NOSOTROS</h2>
          <p className="description">
          En Armat03, creemos en la seguridad basada en confianza y valores sólidos. Nuestra historia comenzó cuando Miguel Torres, técnico en Seguridad, decidió poner su experiencia y conocimientos al servicio de una visión propia en el mundo de la seguridad privada. Con un extenso recorrido en el sector y un compromiso inquebrantable con la integridad, Miguel fundó Armat03 para construir una empresa que refleje su enfoque en la ética y la excelencia.
          Guiados por esta visión, trabajamos cada día para ofrecer las mejores soluciones, buscando relaciones duraderas y de confianza con cada cliente. En Armat, cada proyecto representa nuestro compromiso con un servicio profesional y una promesa de calidad.
          Somos más que una empresa de seguridad, Somos un equipo comprometido en ofrecer seguridad a tu medida! 
          </p>
        </Col>
        <Container className='text-center mt-5'>
          <VideoQuienes className=""/>
        </Container>    
      </Row>
    </Container>
    

  </>
  )
};

export default Quienes;

