import React from 'react';
import { Carousel } from 'react-bootstrap';

const ServiciosCarousel = ({ images, interval = 3000 }) => {
    return (
        <Carousel controls={true} indicators={false} interval={interval} slide>
          {images.map(({ id, src, alt }) => (
            <Carousel.Item key={id}>
              <img
                className="d-block w-100 img-s"
                src={src}
                alt={alt}
              />
            </Carousel.Item>
            
          ))}
        </Carousel>
      );
    };

export default ServiciosCarousel;
