import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const Request = () => {
  const [message, setMessage] = useState(''); 

  const onSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "5ff21f7e-afe1-4a26-9b37-4836b52afcfd");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: json
      });

      const result = await res.json();
      
      if (result.success) {
        event.target.reset();
                setMessage('¡El correo fue enviado exitosamente!'); 
      } else {
        setMessage('Algo salió mal. Por favor, inténtalo de nuevo.'); 
      }
    } catch (error) {
      console.error("Request failed", error);
      setMessage('Error al enviar el correo. Inténtalo más tarde.');
    }
  }
  
  return ( 
    <div>
      <Form onSubmit={onSubmit} className='form-container'>

      <Form.Group className="mb-3" controlId="formGroupName">
          <Form.Control type="text" placeholder="Nombre" name="name" />
        </Form.Group>

        <Form.Group className="mb-3" controlId="formGroupEmail">
          <Form.Control type="email" placeholder="Correo Electrónico" name="email" />
        </Form.Group>
        
        <Form.Group className="mb-3" controlId="formGroupPhone">
          <Form.Control type="number" placeholder="Teléfono" name="phone" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupRequest">
          <Form.Control type="text" placeholder="Motivo de la consulta" name="request" />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formGroupMessage">
          <Form.Control type="text" placeholder="Escriba un mensaje" name="message" />
        </Form.Group>

        <Button type="submit" variant="success">ENVIAR DATOS</Button>
      </Form>

      {message && <p style={{ color: 'green', marginTop: '10px' }}>{message}</p>}
    </div>
  );
}

export default Request;
