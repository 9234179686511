import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useEffect } from 'react';
import { useLocation } from "react-router-dom";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import Inicio from './components/Inicio';
import Quienes from './components/Quienes';
import Servicios from './components/Servicios';
import Contacto from './components/Contacto';
import Request2 from './components/Request2';
import ScrollToTop from './components/ScrollToTop';


function App() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const targetElement = document.querySelector(location.hash);
        if (targetElement) {
          const headerHeight = document.querySelector('header')?.offsetHeight || 0;
          const elementPosition = targetElement.getBoundingClientRect().top + window.scrollY - headerHeight;
          window.scrollTo({
            top: elementPosition,
            behavior: "auto",
          });
        }
      }, 1000); 
    }
  }, [location]);
  

  return (
    <>
      <ScrollToTop/>
      <Header />
      <Routes>
        <Route path="/" element={<Inicio />} />
        <Route path="/quienes" element={<Quienes />} />
        <Route path="/servicios" element={<Servicios />} />
        <Route path="/servicios#dispositivos" element={<Servicios />} />
        <Route path="/servicios#barrio-cerrado" element={<Servicios />} />
        <Route path="/servicios#comercios" element={<Servicios />} />
        <Route path="/contacto" element={<Contacto />} />
      </Routes>
      <Request2/>
      <Footer />    
    </>
  );
}

export default App;
