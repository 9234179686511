import React from 'react';
import logo from '../assets/cropped-Negro-371x176.png'
import { Container, Row, Col } from 'react-bootstrap';
import '../App.css'; 

const Footer = () => {

  return (
    <footer className="footer-custom py-5">
      <Container>
        <Row className="align-items-center">
        <Col md={4} className="text-center">
            <img
              src={logo}
              alt="Armat Seguridad Privada"
              className="footer-logo"
            />
          </Col>
          <Col md={4} className="text-left text-white">
            <p>Copyright © 2024 armatseguridad.com</p>
          </Col>
          <Col md={4} className="text-right text-white">
            <p>Powered by armatseguridad.com</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
